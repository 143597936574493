import React, { useState } from "react"
import { graphql } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Media({ data }) {
  return (
    <Layout>
      <SEO title="Media" />

      <h1 className="text-4xl text-salmon uppercase tracking-wide">Media</h1>

      <div className="lg:mx-auto lg:max-w-6xl">
        <div className="flex flex-wrap justify-around items-start">
          {data.media.edges.map(({ node }) => {
            return <MediaObject key={node.id} {...node} />
          })}
        </div>
      </div>
    </Layout>
  )
}

function MediaObject({ name, publicURL, extension }) {
  const [shown, setShown] = useState(false)
  const [ref, inView] = useInView()

  if (inView && !shown) {
    setShown(true)
  }

  const Component =
    extension === "mp4" || extension === "webm" ? "video" : "img"

  return (
    <a href={publicURL} ref={ref}>
      <Component
        src={shown ? publicURL : ""}
        alt={name}
        className="flex-1 border border-gray-400 sm:w-full md:w-full max-w-lg m-2"
        style={{ minWidth: "10rem", minHeight: "10rem" }}
        autoPlay
        loop
        muted
      />
    </a>
  )
}

export const query = graphql`
  query Media {
    media: allFile(
      filter: { sourceInstanceName: { eq: "media" } }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          id
          name
          publicURL
          extension
        }
      }
    }
  }
`
